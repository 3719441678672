export const environment = {
  production: false,
  API_URL: 'https://backend-beta.blocktinu.com/api',
  BUILD_URL: 'https://build-beta.blocktinu.com',
  SOCIAL_AUTH_REDIRECT_URI: 'https://backend-beta.blocktinu.com/auth/social/',
  WEBSOCKET_PROTOCOL: "wss",
  WEBSOCKET_URL: 'backend-beta.blocktinu.com/blocktinu_ws',
  COOKIE_TOKEN_KEY: 'TOKEN_INFO',
  PUBLIC_ORIGIN: 'https://webui-beta.blocktinu.com',
};
